import { Stack, Tooltip, Wrap } from '@chakra-ui/react';
import {
	commonCw,
	firstStep,
	projects,
	systemHealthStatus,
} from '../../../../@xmcloud/core/messages/en';
import {
	HeadingLg,
	InputFormControl,
	AlertStatus,
	XMCSButton,
} from '../../../shared-components';
import { useComponentHealthy } from '../../../../@xmcloud/hooks';
import {
	useGetProjectLimitation,
	useGetValidateProjectName,
} from '../../../services/projects';
import { PROJECT_NAME } from '../helpers';
import { useCreateProject } from '../hooks';

const { continue: continuetxt } = commonCw;
const { title: titletxt } = firstStep;
const { errMsg1, errMsg2 } = systemHealthStatus;
const { nameTitle, requiredName, canNotCreateProject } = projects;

export const OLDProjectName: React.FC = () => {
	const {
		values,
		errors,
		handleChange,
		next,
		setFieldError,
		setFieldTouched,
		touched,
	} = useCreateProject();
	const {
		isBlockersHealthy,
		isLoading: isComponentHealthLoading,
		isError,
	} = useComponentHealthy({});

	const {
		refetch,
		isLoading: isValidationLoading,
		isFetching,
	} = useGetValidateProjectName(values.name);

	const { data: projectLimitation, isLoading: isProjectLimitationLoading } =
		useGetProjectLimitation();

	async function handleNext() {
		if (!values.name) {
			setFieldTouched(PROJECT_NAME, true);
			setFieldError(PROJECT_NAME, requiredName);
			return;
		}
		if (errors.name) {
			setFieldTouched(PROJECT_NAME, true);
			return;
		}

		const { data } = await refetch();
		const isValid = data?.data.isValid;
		const message = data?.data.message as string;
		if (!isValid) {
			setFieldError(PROJECT_NAME, message);
			return;
		}
		next();
	}

	const isLimitExceeds = projectLimitation?.data.isLimitExceeds;
	const limitExceededError = canNotCreateProject;

	const isLoading =
		isComponentHealthLoading ||
		isValidationLoading ||
		isProjectLimitationLoading ||
		isFetching;

	const isSystemNotHealthy = !isBlockersHealthy && !isComponentHealthLoading;
	const isNextBtnDisabled = isSystemNotHealthy || !!isLimitExceeds;

	const errorMsg = isLimitExceeds
		? limitExceededError
		: isError
			? errMsg1
			: errMsg2;

	const showErrorAlert = isNextBtnDisabled && !isLoading;

	return (
		<Stack gap={5}>
			<HeadingLg text={titletxt} />
			<InputFormControl
				{...{
					isInvalid: Boolean(errors.name && touched.name),
					label: nameTitle,
					name: PROJECT_NAME,
					value: values.name,
					onChange: handleChange,
					error: errors.name,
					onFocus: () => setFieldTouched(PROJECT_NAME, true),
					maxW: 'sm',
					isRequired: true,
				}}
			/>
			<Wrap py="1">
				<Tooltip
					data-testid="tooltip-continue-btn"
					label={errorMsg}
					shouldWrapChildren
					isDisabled={!showErrorAlert}
				>
					<XMCSButton
						text={continuetxt}
						onClick={handleNext}
						alignSelf="start"
						size="md"
						isLoading={isLoading}
						isDisabled={isNextBtnDisabled}
					/>
				</Tooltip>
			</Wrap>
			{showErrorAlert && (
				<AlertStatus
					description={errorMsg}
					status="error"
					maxW="sm"
					data-testid="bloker-error-alert"
				/>
			)}
		</Stack>
	);
};
