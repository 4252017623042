import { useState } from 'react';
import { useDeploymentDeployMutation } from '../../../services/deployments';
import {
	useCreateEnvironmentDeploymentMutation,
	useLinkRepositoryEnvironmentMutation,
	useUpsertEnvironmentVariableMutation,
} from '../../../services/environments';
import { useCreateProjectEnvironmentMutation } from '../../../services/projects';
import { goTo } from '../../../../@xmcloud/core/routes/paths';
import {
	handlePush,
	ResponseStatusEnum,
} from '../../../../@xmcloud/utils/helpers';
import {
	CM_ENVIRONMENT_ID,
	EnvironmentTypesEnum,
	getProcessStatus,
} from '../helpers';
import { ESourceControl, TenantType } from '../../../../@xmcloud/types';
import { useCreateProject } from '.';

const { CM, EH } = EnvironmentTypesEnum;
const { SUCCESS, ERROR } = ResponseStatusEnum;
const { ADO } = ESourceControl;
const { NonProduction } = TenantType;

const {
	deploymentLogs: goToDeploymentLogs,
	allDeployments: goToAllDeployments,
} = goTo;

export function useEnvironmentCreation({
	shouldGoto = false,
	onCmEnvironmentCreationSuccess,
	environmentType = CM,
}: {
	shouldGoto?: boolean;
	onCmEnvironmentCreationSuccess?: (
		cmEnvironmentId: string,
		projectId: string,
	) => void;
	environmentType?: EnvironmentTypesEnum;
}) {
	const [currentEnvironmentId, setCurrentEnvironmentId] = useState('');
	const [currentDeploymentId, setCurrentDeploymentId] = useState('');

	const { values, isByoc, setFieldValue, isSingleEnvironmentCreation } =
		useCreateProject();

	const {
		cmEnvironmentName,
		tenantType,
		cmDeployOnCommit,
		linkEnvironmentToDefaultBranch,
		cmRepositoryBranch,
		cmRepository,
		cmRepositoryId,
		ehEnvironmentName,
		ehDeployOnCommit,
		ehRepositoryBranch,
		ehRepository,
		ehRepositoryId,
		cmEnvironmentVariables,
		ehEnvironmentVariables,
		ehSourceControlIntegrationId,
		cmSourceControlIntegrationId,
		cmSourceControlIntegrationName,
		ehSourceControlIntegrationName,
		cmEnvironmentId,
		cmAdoOrganization,
		cmAdoProjectName,
		ehAdoOrganization,
		ehAdoProjectName,
		cmProvider,
		ehProvider,
	} = values;

	const isEH = environmentType === EH;

	const cmRepoPath = `${cmAdoOrganization}/${cmAdoProjectName}/${cmRepository}`;
	const ehRepoPath = `${ehAdoOrganization}/${ehAdoProjectName}/${ehRepository}`;

	const getCurrentRepo = () => {
		const repoPath = isEH ? ehRepoPath : cmRepoPath;
		const repository = isEH ? ehRepository : cmRepository;
		const provider = isEH ? ehProvider : cmProvider;

		if (isByoc) {
			return provider === ADO ? repoPath : repository;
		}
		return cmProvider === ADO ? cmRepoPath : cmRepository;
	};

	const {
		mutate: createEnvironment,
		isLoading: createEnvironmentIsLoading,
		status: createEnvironmentStatus,
	} = useCreateProjectEnvironmentMutation();
	const {
		mutate: createDeployment,
		isLoading: createDeploymentIsLoading,
		status: createDeploymentStatus,
	} = useCreateEnvironmentDeploymentMutation();
	const {
		mutate: deploymentDeploy,
		isLoading: deploymentDeployIsLoading,
		status: deploymentDeployStatus,
	} = useDeploymentDeployMutation();

	const {
		mutate: linkRepositoryToEnvironment,
		isLoading: isLoadingLinkRepositoryEnvironment,
		status: linkRepositoryEnvironmentStatus,
	} = useLinkRepositoryEnvironmentMutation({
		id: currentEnvironmentId,
	});
	const {
		mutate: createVariable,
		isLoading: isCreateVariableMutationLoading,
		status: createEnvironmentVariableStatus,
	} = useUpsertEnvironmentVariableMutation();

	const linkRepositoryEnvStatus = isByoc
		? linkRepositoryEnvironmentStatus
		: SUCCESS;

	//1st step: creation of environment after project creation failure
	const shouldRetryFirstStep = createEnvironmentStatus === ERROR;
	// 2nd step: creation of deployment after environment creation failure
	const shouldRetrySeconsStep = linkRepositoryEnvironmentStatus === ERROR;
	// 3rd step: trigger deployment after deployment creation failure
	const shouldRetryThirdProcess = createDeploymentStatus === ERROR;
	// 4th step: deployment trigger after  failure
	const shouldRetryFourthProcess = deploymentDeployStatus === ERROR;
	// 5th step: environment variable creation failure
	const shouldRetryFifthProcess = createEnvironmentVariableStatus === ERROR;

	//4th step: environment variables creation
	const onCreateVariables = (
		projectId: string,
		environmentId: string,
		deploymentId: string,
	) => {
		let promises = [] as any[];
		const variables = isEH
			? ehEnvironmentVariables
			: cmEnvironmentVariables;

		for (const variable of variables) {
			const { name, secret, value } = variable;
			promises.push(
				createVariable(
					{
						environmentId: environmentId,
						variableName: name,
						data: {
							secret: secret,
							value: value,
							target: environmentType,
						},
					},
					{},
				),
			);
		}

		Promise.allSettled(promises).finally(() => {
			if (isSingleEnvironmentCreation) {
				handlePush(
					goToDeploymentLogs(
						projectId,
						environmentId,
						deploymentId,
						CM,
					),
				);
				return;
			}
			if (shouldGoto) {
				handlePush(goToAllDeployments);
				return;
			}

			onCmEnvironmentCreationSuccess &&
				onCmEnvironmentCreationSuccess(environmentId, projectId);
		});
	};

	//3rd step: trigger deployment after deployment creation
	const onDeploymentDeploy = (
		projectId: string,
		environmentId: string,
		deploymentId: string,
	) => {
		deploymentDeploy(
			{ deploymentId },
			{
				onSuccess: () => {
					const variables = isEH
						? ehEnvironmentVariables
						: cmEnvironmentVariables;

					if (!variables.length && isSingleEnvironmentCreation) {
						handlePush(
							goToDeploymentLogs(
								projectId,
								environmentId,
								deploymentId,
								CM,
							),
						);
						return;
					}
					if (!variables.length && shouldGoto) {
						handlePush(goToAllDeployments);
						return;
					}

					if (variables.length) {
						onCreateVariables(
							projectId,
							environmentId,
							deploymentId,
						);
					} else {
						onCmEnvironmentCreationSuccess &&
							onCmEnvironmentCreationSuccess(
								environmentId,
								projectId,
							);
					}
				},
			},
		);
	};

	//2nd step: creation of deployment after environment creation
	const onDeploymentCreation = (projectId: string, environmentId: string) => {
		createDeployment(
			{ environmentId },
			{
				onSuccess: (res) => {
					const deploymentId = res.data?.id || '';
					setCurrentDeploymentId(deploymentId);
					onDeploymentDeploy(projectId, environmentId, deploymentId);
				},
			},
		);
	};

	//edit environment for bring your own code step
	const onLinkRepositoryEnvironment = (
		projectId: string,
		environmentId: string,
	) => {
		const repo = getCurrentRepo();
		linkRepositoryToEnvironment(
			{
				repository: repo,
				repositoryId: isEH ? `${ehRepositoryId}` : `${cmRepositoryId}`,
				integrationId: isEH
					? ehSourceControlIntegrationId
					: cmSourceControlIntegrationId,
				repositoryBranch: isEH
					? ehRepositoryBranch
					: cmRepositoryBranch,
				environmentId,
				sourceControlIntegrationName: isEH
					? ehSourceControlIntegrationName
					: cmSourceControlIntegrationName,
				deployOnCommit: isEH ? ehDeployOnCommit : cmDeployOnCommit,
			},
			{
				onSuccess: () => {
					onDeploymentCreation(projectId, environmentId);
				},
			},
		);
	};

	//1st step: creation of environment(onEnvironmentCreation)
	const onEnvironmentCreation = (
		projectId: string,
		environmentId: string,
	) => {
		const repo = getCurrentRepo();

		const envPayload = {
			name: isEH ? ehEnvironmentName : cmEnvironmentName,
			tenantType: isEH ? NonProduction : tenantType,
			deployOnCommit: isEH ? ehDeployOnCommit : cmDeployOnCommit,
			linkEnvironmentToDefaultBranch: linkEnvironmentToDefaultBranch,
			type: environmentType,
			repositoryBranch: isByoc ? '' : cmRepositoryBranch,
			repositoryDetails: isByoc
				? null
				: {
						repositoryLinkedAt: new Date().toISOString(),
						repository: repo,
						repositoryId: `${cmRepositoryId}`,
						sourceControlIntegrationId:
							cmSourceControlIntegrationId,
						sourceControlIntegrationName:
							cmSourceControlIntegrationName,
					},
			...(isEH && {
				editingHostEnvironmentDetails: {
					cmEnvironmentId: cmEnvironmentId || environmentId,
				},
			}),
		};

		createEnvironment(
			{ projectId, environment: envPayload },
			{
				onSuccess: (res) => {
					const newEnvironment = res.data;
					setCurrentEnvironmentId(newEnvironment.id);
					setFieldValue(CM_ENVIRONMENT_ID, newEnvironment.id);

					if (isByoc) {
						onLinkRepositoryEnvironment(
							projectId,
							newEnvironment.id,
						);
						return;
					}
					onDeploymentCreation(projectId, newEnvironment.id);
				},
			},
		);
	};

	function onEnvironmentLevelProcess(
		projectId: string,
		cmEnvironmentId: string,
	) {
		if (shouldRetryFirstStep) {
			onEnvironmentCreation(projectId, cmEnvironmentId);
		} else if (shouldRetrySeconsStep) {
			onLinkRepositoryEnvironment(projectId, currentEnvironmentId);
		} else if (shouldRetryThirdProcess) {
			onDeploymentCreation(projectId, currentEnvironmentId);
		} else if (shouldRetryFourthProcess) {
			onDeploymentDeploy(
				projectId,
				currentEnvironmentId,
				currentDeploymentId,
			);
		} else if (shouldRetryFifthProcess) {
			onCreateVariables(
				projectId,
				currentEnvironmentId,
				currentDeploymentId,
			);
		} else {
			onEnvironmentCreation(projectId, cmEnvironmentId);
		}
	}

	const environmentCreationStatus = getProcessStatus(
		createEnvironmentStatus,
		linkRepositoryEnvStatus,
	);
	const deploymentCreationStatus = getProcessStatus(
		createDeploymentStatus,
		deploymentDeployStatus,
	);
	const environmentVariableCreationStatus = getProcessStatus(
		createEnvironmentVariableStatus,
	);

	const isLoading =
		createEnvironmentIsLoading ||
		createDeploymentIsLoading ||
		deploymentDeployIsLoading ||
		isLoadingLinkRepositoryEnvironment ||
		isCreateVariableMutationLoading;

	const shouldRetry =
		shouldRetryFirstStep ||
		shouldRetrySeconsStep ||
		shouldRetryThirdProcess ||
		shouldRetryFourthProcess ||
		shouldRetryFifthProcess;

	return {
		isLoading,
		onEnvironmentLevelProcess,
		environmentCreationStatus,
		deploymentCreationStatus,
		environmentVariableCreationStatus,
		shouldRetry,
	};
}
