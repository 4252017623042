import { Stack, Tooltip } from '@chakra-ui/react';
import { Icon, XMCSButton } from '../../../shared-components';
import { commonCw } from '../../../../@xmcloud/core/messages/en';
import { mdiArrowRight } from '@mdi/js';

interface Props {
	onPreviousClick: () => void;
	onNextClick: () => void;
	isLoading?: boolean;
	py?: number;
	type?: 'button' | 'submit' | 'reset';
	rightBtnText?: string;
	isDisabled?: boolean;
	tooltiptxt?: string;
	showPreviousBtn?: boolean;
	showRightIcon?: boolean;
	colorScheme?: 'success' | 'purple';
}
const { previous, continue: continueText } = commonCw;

export const FooterBtnGroups: React.FC<Props> = ({
	onPreviousClick,
	onNextClick,
	isLoading,
	py = 7,
	type = 'button',
	rightBtnText = continueText,
	tooltiptxt = '',
	isDisabled,
	showPreviousBtn = true,
	showRightIcon = true,
	colorScheme = 'purple',
}) => (
	<Stack py={py} direction="row">
		{showPreviousBtn && (
			<XMCSButton
				text={previous}
				onClick={onPreviousClick}
				size="md"
				variant="outline"
			/>
		)}
		<Tooltip
			label={tooltiptxt}
			shouldWrapChildren
			isDisabled={!isDisabled || isLoading}
		>
			<XMCSButton
				text={rightBtnText}
				onClick={onNextClick}
				size="md"
				isLoading={isLoading}
				type={type}
				isDisabled={isLoading || isDisabled}
				{...(showRightIcon && {
					rightIcon: <Icon path={mdiArrowRight} />,
				})}
				colorScheme={colorScheme}
			/>
		</Tooltip>
	</Stack>
);
