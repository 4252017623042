import { Divider, Stack } from '@chakra-ui/react';
import { HeadingLg, Text } from '../../../shared-components';
import { thirdStep } from '../../../../@xmcloud/core/messages/en';
import { FooterBtnGroups } from '../components';
import { useValidateSourceControlIntegrationV2 } from '../../../services/repositories';
import { useGetSourceControlAccessToken } from '../../../services/accessToken';
import {
	CM_ENVIRONMENT_NAME,
	CM_ENVIRONMENT_VARIABLES,
	CM_PROVIDER,
	CM_SOURCE_CONTROL_INTEGRATION_ID,
	EnvironmentTypesEnum,
} from '../helpers';
import { ESourceControl } from '../../../../@xmcloud/types';
import { EnvVars } from '../../../features/custom-environment-variables';
import {
	AdoRepository,
	EnvironmentName,
	EnvironmentVariables,
	GithubRepository,
	Options,
	SetupProviderAccount,
} from '../../../features/common-env-fields';
import { useCreateProject, useValidateCmAndEhSteps } from '../hooks';

const { info1, nameCmEnv, cmPageTitle, cmSubTitle } = thirdStep;

const { CM } = EnvironmentTypesEnum;
const { ADO, GitHub } = ESourceControl;

const repoLookup = {
	[ADO]: (props: any) => <AdoRepository {...props} />,
	[GitHub]: (props: any) => <GithubRepository {...props} />,
	'': () => <></>,
};

export const SetupCmEnvironment: React.FC = () => {
	const {
		values,
		errors,
		handleChange,
		setFieldTouched,
		touched,
		prev,
		setValues,
		setErrors,
		setFieldValue,
		setTouched,
		isByoc,
	} = useCreateProject();

	const sourceControlIntegrationId = values[CM_SOURCE_CONTROL_INTEGRATION_ID];

	const {
		isFetching: isSourceControlIntegrationLoading,
		isIntegrationIdValidationValid,
		integrationValidationErrorMsg,
	} = useValidateSourceControlIntegrationV2({
		integrationId: sourceControlIntegrationId,
		enabled: !!sourceControlIntegrationId,
	});
	const shouldCallAccessTokenApi =
		isIntegrationIdValidationValid && !isSourceControlIntegrationLoading;

	const { data: accessTokenData, isLoading: isLoadingAccessToken } =
		useGetSourceControlAccessToken(
			sourceControlIntegrationId,
			shouldCallAccessTokenApi,
		);
	const accessToken = accessTokenData?.data.accessToken || '';
	const isLoading = isSourceControlIntegrationLoading || isLoadingAccessToken;

	const { handleNext, isLoading: validationIsLoading } =
		useValidateCmAndEhSteps({
			isIntegrationIdValidationValid,
			integrationValidationErrorMsg,
			nameSpace: CM,
		});

	const CurrentRepository = repoLookup[values[CM_PROVIDER]];

	return (
		<Stack gap="6" pb="10">
			<HeadingLg text={cmPageTitle} />
			<Text text={cmSubTitle} />
			<EnvironmentName
				{...{
					values,
					errors,
					touched,
					handleChange,
					setFieldTouched,
					helperText: info1,
					label: nameCmEnv,
					environmentNameSpace: CM_ENVIRONMENT_NAME,
				}}
			/>
			<Divider />
			<SetupProviderAccount
				{...{
					values,
					errors,
					touched,
					setValues,
					setTouched,
					setErrors,
					setFieldTouched,
					isIntegrationIdValidationValid,
					integrationValidationErrorMsg,
					isSourceControlIntegrationLoading,
					nameSpace: CM,
				}}
			/>
			<CurrentRepository
				{...{
					isSourceControlIntegrationLoading: isLoading,
					nameSpace: CM,
					accessToken,
					values,
					errors,
					touched,
					setValues,
					setFieldTouched,
					setFieldValue,
					isByoc,
					showRepoCombobox: isByoc,
					handleChange,
				}}
			/>
			<Divider />
			<Options
				{...{
					nameSpace: CM,
					values,
					errors,
					setFieldValue,
				}}
			/>
			<Divider />
			<EnvVars
				{...{
					isExistingProject: false,
					initialVariables: values.cmEnvironmentVariables,
					onVariablesChange: (variables) => {
						setFieldValue(CM_ENVIRONMENT_VARIABLES, variables);
					},
				}}
			>
				<EnvironmentVariables
					variables={values.cmEnvironmentVariables}
				/>
			</EnvVars>
			<Divider />
			<FooterBtnGroups
				{...{
					onNextClick: handleNext,
					onPreviousClick: prev,
					isLoading: validationIsLoading || isLoading,
					py: 0,
				}}
			/>
		</Stack>
	);
};
