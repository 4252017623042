import { MenuItem, MenuList, Text, Tooltip } from '@chakra-ui/react';
import {
	HostingProvider,
	IGetHostingInstallation,
} from '../../../models/hostingModel';
import { connections } from '../../../../@xmcloud/core/messages/en';
import { t } from '@transifex/native';
import { getReadableDate } from '../../../../@xmcloud/utils/dateUtils';
import { RowActions } from '../../../shared-components';
import { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { useSharedState } from '../../../../@xmcloud/hooks';
import { USER_EMAIL } from '../../../../@xmcloud/hooks/context/useSharedState';
import { useFeature } from 'app/feature-flag/features';

const columnHelper = createColumnHelper<IGetHostingInstallation>();

const {
	providerName,
	connectionName,
	connectionId,
	createdBy,
	createdAt,
	deleteValidationMessage,
	vercel,
} = connections;

export const useHostingConnectionsTableColumns = (
	setSelectedInstallation: (s: string) => void,
	setDeleteConnectionModal: (v: boolean) => void,
) => {
	const { state: userEmail } = useSharedState(USER_EMAIL, '');
	const isAllowDeleteHostByOrganization = useFeature(
		'AllowDeleteHostByOrganization',
	);

	return useMemo(
		() => [
			columnHelper.accessor('name', {
				header: () => t(connectionName),
				cell: (info) => info.getValue(),
			}),
			columnHelper.accessor('provider', {
				header: () => t(providerName),
				cell: (info) =>
					info.getValue() === HostingProvider.Vercel
						? vercel
						: info.getValue(),
			}),
			columnHelper.accessor('id', {
				header: () => t(connectionId),
				cell: (info) => info.getValue(),
			}),
			columnHelper.accessor('createdBy', {
				header: () => t(createdBy),
				cell: (info) => (
					<Text noOfLines={1} whiteSpace="wrap">
						{info.getValue()}
					</Text>
				),
			}),
			columnHelper.accessor('createdAt', {
				header: () => t(createdAt),
				cell: (info) => getReadableDate(info.getValue()),
			}),
			columnHelper.display({
				id: 'actions',
				cell: ({
					row: {
						original: { id, createdBy },
					},
				}) => {
					return (
						<RowActions ariaLabel="connections-hosting-table-action-menu">
							<MenuList>
								<Tooltip
									label={t(deleteValidationMessage)}
									isDisabled={
										isAllowDeleteHostByOrganization ||
										userEmail === createdBy
									}
									shouldWrapChildren
								>
									<MenuItem
										isDisabled={
											!isAllowDeleteHostByOrganization &&
											userEmail !== createdBy
										}
										onClick={() => {
											setSelectedInstallation(id);
											setDeleteConnectionModal(true);
										}}
										minW="3xs"
									>
										{t(connections.delete)}
									</MenuItem>
								</Tooltip>
							</MenuList>
						</RowActions>
					);
				},
				maxSize: 0,
			}),
		],
		[userEmail, setDeleteConnectionModal, setSelectedInstallation],
	);
};
