import * as yup from 'yup';
import {
	environments,
	thirdStep,
	secondStep,
	fourthStep,
	editCreateEnvironment,
} from '../core/messages/en';
import {
	EH_ENV_NAME_VALIDATION_PATTERN,
	ENVIRONMENT_NAME_VALIDATION_PATTERN,
} from '../utils/constants';
import { t } from '@transifex/native';
import { TenantType } from '../types';

const { requiredEnvironmentName, invalidEnvironmentName } = environments;
const { maxCharacters: envMaxCharacters } = thirdStep;
const {
	repoRequired,
	orgRequired,
	projectNameRequired,
	repositoryNameValidationMessage,
	repoBranchRequired,
} = secondStep;
const { requriedCmEnv } = fourthStep;
const {
	needToContinue,
	tenantTypeValidation1,
	tenantTypeValidation2,
	tenantTypeValidation3,
	tenantTypeValidation4,
	requiredAccount,
} = editCreateEnvironment;

const { Production, NonProduction } = TenantType;

const notRequired = () => yup.string().notRequired();

const validateRepository = (isRequired: boolean) =>
	isRequired
		? yup
				.string()
				.required(repoRequired)
				.matches(
					/^[A-Za-z0-9_.-]{1,100}$/,
					repositoryNameValidationMessage,
				)
		: notRequired();

const validate = (isRequired: boolean, msg: string) =>
	isRequired ? yup.string().required(msg) : notRequired();

const validateEnvironmentName = (isCm: boolean) =>
	yup
		.string()
		.required(requiredEnvironmentName)
		.max(100, envMaxCharacters)
		.matches(
			new RegExp(
				isCm
					? ENVIRONMENT_NAME_VALIDATION_PATTERN
					: EH_ENV_NAME_VALIDATION_PATTERN,
			),
			invalidEnvironmentName,
		);

export const createEditEnvValidationSchema = ({
	isAdoProvider,
	environmentPerProjectLimitation,
	allEnvironmentsLimitation,
	isCreate,
	isCm,
}: {
	isAdoProvider: boolean;
	environmentPerProjectLimitation: any;
	allEnvironmentsLimitation: any;
	isCreate: boolean;
	isCm: boolean;
}) =>
	yup.object({
		environmentName: validateEnvironmentName(isCm),
		sourceControlIntegrationId: validate(true, requiredAccount),
		repository: validateRepository(true),
		adoProjectName: validate(isAdoProvider, projectNameRequired),
		adoOrganization: validate(isAdoProvider, orgRequired),
		cmEnvironmentId: validate(!isCm, requriedCmEnv),
		repositoryBranch: validate(true, repoBranchRequired),
		tenantType:
			isCreate && isCm
				? yup
						.string()
						.required(t(needToContinue))
						.test(
							'environment-limit-validation',
							'Environment tier validation failed',
							function (value) {
								const { path, createError } = this;

								if (
									value === Production.toString() &&
									environmentPerProjectLimitation?.data
										?.isProdLimitExceeds
								) {
									return createError({
										path,
										message: t(tenantTypeValidation1),
									});
								} else if (
									value === NonProduction.toString() &&
									environmentPerProjectLimitation?.data
										?.isNonProdLimitExceeds
								) {
									return createError({
										path,
										message: t(tenantTypeValidation2),
									});
								}
								return true;
							},
						)
						.test(
							'all-environment-limit-validation',
							'All environment tier validation failed',
							function (value) {
								const { path, createError } = this;

								if (
									value === Production.toString() &&
									allEnvironmentsLimitation?.data
										.isProdLimitExceeds
								) {
									return createError({
										path,
										message: t(tenantTypeValidation3),
									});
								} else if (
									value === NonProduction.toString() &&
									allEnvironmentsLimitation?.data
										.isNonProdLimitExceeds
								) {
									return createError({
										path,
										message: t(tenantTypeValidation4),
									});
								}
								return true;
							},
						)
				: notRequired(),
	});
