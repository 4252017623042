import { useState } from 'react';
import { HStack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useSharedState } from '../../../../../@xmcloud/hooks';
import { IS_SM_SCREEN } from '../../../../../@xmcloud/hooks/context/useSharedState';
import {
	Footer,
	LoadingSkeleton,
	RenderPlaceholder,
	Table,
	XMCSButton,
} from '../../../../shared-components';
import { pageSize } from '../../../../../@xmcloud/utils/constants';
import { useEnvironmentsTableColumns } from '../../columns/environments';
import { ColumnDef } from '@tanstack/react-table';
import { IGetEnvironmentResponseV2 } from '../../../../models/environmentModel';
import CreateCombinedEnvironment from '../../../../stale-components/CreateCombinedEnvironment';
import { useGetProjectEnvironmentsList } from '../../../../services/projects';

const headers = ['Name', 'ID', 'Date created', ''];

export const EnvironmentsPageLegacy: React.FC = () => {
	const { projectId } = useParams<{
		projectId: string;
	}>();
	const [openCreateModal, setOpenCreateModal] = useState(false);
	const { state: isSmScreen } = useSharedState(IS_SM_SCREEN, false);

	const { data, isLoading } = useGetProjectEnvironmentsList({
		projectId: projectId!,
	});

	const environments = data?.data || [];

	const columns: ColumnDef<IGetEnvironmentResponseV2, any>[] =
		useEnvironmentsTableColumns();

	if (isLoading) return <LoadingSkeleton amount={pageSize + 1} />;

	return (
		<>
			<HStack justify="flex-end" py={isSmScreen ? 3 : 5}>
				{!isSmScreen && (
					<XMCSButton
						{...{
							text: 'Create environment',
							onClick: () => setOpenCreateModal(true),
						}}
					/>
				)}
			</HStack>
			{environments.length === 0 ? (
				<RenderPlaceholder item="environments" />
			) : (
				<Table
					{...{
						columns: columns,
						tableData: environments,
						dataTestId: 'environments-table',
						showPagination: environments.length > pageSize,
						headers,
					}}
				/>
			)}
			{!!isSmScreen && (
				<Footer>
					<XMCSButton
						text="Create environment"
						onClick={() => setOpenCreateModal(true)}
					/>
				</Footer>
			)}
			{openCreateModal && (
				<CreateCombinedEnvironment
					openModal={openCreateModal}
					setOpenModal={setOpenCreateModal}
				/>
			)}
		</>
	);
};
