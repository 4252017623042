import { cleanEmptyProperties, handlePush } from '../../@xmcloud/utils/helpers';
import { AxiosResponse } from 'axios';
import { useQueryClient } from 'react-query';
import {
	IEnvironmentDetails,
	IGetEnvironmentResponseV2,
	IGetEnvironmentsLimitation,
} from '../models/environmentModel';
import { useAuthMutation, useAuthQuery } from '../apiUtils/queryHelper';
import config from '../config/config';

import {
	ICreateProjectEnvironmentResponse,
	ICreateUpdateProjectPayload,
	IGetProjectLimitation,
	IGetProjectResponse,
	IGetProjectResponseV2,
	ILinkRepositoryPayload,
	IProjectCreateResponse,
	IValidateProjectNameResponse,
} from '../models/projectModel';
import { goTo } from '../../@xmcloud/core/routes/paths';
import { useFeature } from '../feature-flag/features';
import { normalizeEnvironmentResponse } from './environments';

export const useCreateProjectMutation = () => {
	const queryClient = useQueryClient();
	const { create_project, get_projects_list } = config.projects;
	const { scope, url } = create_project;

	return useAuthMutation(
		(axiosInstance) =>
			({ project }: { project: ICreateUpdateProjectPayload }) => {
				const payload = cleanEmptyProperties(project);

				return axiosInstance?.post<
					ICreateUpdateProjectPayload,
					AxiosResponse<IProjectCreateResponse>
				>(url, payload);
			},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(get_projects_list.queryKey);
			},
		},
		scope,
	);
};

// TODO: Once migrated to IGetProjectResponseV2 api version the function normalizeProjectResponse will be removed and the IGetProjectResponse will be replaced with IGetProjectResponseV2
export const normalizeProjectResponse = (
	data: IGetProjectResponse | IGetProjectResponseV2,
): IGetProjectResponseV2 => {
	return {
		...(data as IGetProjectResponseV2),
		environments: (data as IGetProjectResponseV2)?.environments || [],
		sitecoreMajorVersion:
			(data as IGetProjectResponseV2).sitecoreMajorVersion || 0,
		sitecoreMinorVersion:
			(data as IGetProjectResponseV2).sitecoreMinorVersion || 0,
	};
};

export const useGetProject = ({
	id,
	enabled,
}: {
	id: string;
	enabled?: boolean;
}) => {
	const splitEhAndCm = useFeature('SplitEhAndCm');
	const CreateProjectV3 = useFeature('CreateProjectV3');
	const shouldUseV2 = CreateProjectV3 || splitEhAndCm;
	const { project, project_v2 } = config.projects;
	const { scope, url: urlV1, queryKey } = project;
	const { url: urlV2 } = project_v2;
	const url = shouldUseV2 ? urlV2 : urlV1;

	return useAuthQuery(
		queryKey(id),
		async (axiosInstance) => {
			const response = await axiosInstance?.get<IGetProjectResponse>(
				url(id),
			);

			return {
				...response,
				data: normalizeProjectResponse(response.data),
			};
		},
		{ enabled },
		scope,
	);
};

export const useUpdateProjectMutation = ({ id }: { id: string }) => {
	const queryClient = useQueryClient();
	const { scope, url, queryKey } = config.projects.project;
	return useAuthMutation(
		(axiosInstance) => (projectData: ICreateUpdateProjectPayload) => {
			return axiosInstance?.put(url(id), projectData);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(
					config.projects.get_projects_list.queryKey,
				);
				queryClient.invalidateQueries(queryKey(id));
			},
		},
		scope,
	);
};

export const useDeleteProjectMutation = ({ id }: { id: string }) => {
	const queryClient = useQueryClient();
	const { scope, url, queryKey } = config.projects.project;
	return useAuthMutation(
		(axiosInstance) => () => {
			return axiosInstance?.delete(url(id));
		},
		{
			onSuccess: () => {
				handlePush(goTo.main);
				queryClient.invalidateQueries(
					config.projects.get_projects_list.queryKey,
				);
				queryClient.invalidateQueries(queryKey(id));
			},
		},
		scope,
	);
};

export const useGetProjectList = () => {
	const splitEhAndCm = useFeature('SplitEhAndCm');
	const { scope, url: urlV1, queryKey } = config.projects.get_projects_list;
	const { url: urlV2 } = config.projects.get_projects_list_v2;
	const url = splitEhAndCm ? urlV2 : urlV1;

	return useAuthQuery(
		queryKey,
		async (axiosInstance) => {
			const response =
				await axiosInstance?.get<IGetProjectResponse[]>(url);

			return {
				...response,
				data: response.data.map(normalizeProjectResponse),
			};
		},
		{},
		scope,
	);
};

export const useGetProjectLimitation = () => {
	const { scope, url, queryKey } = config.projects.projects_Limitation;

	return useAuthQuery(
		queryKey,
		(axiosInstance) => {
			return axiosInstance?.get<IGetProjectLimitation>(url);
		},
		{},
		scope,
	);
};

export const useGetEnvironmentsLimitation = ({
	projectId,
	enabled,
}: {
	projectId: string;
	enabled?: boolean;
}) => {
	const { scope, url, queryKey } = config.projects.environments_Limitation;
	return useAuthQuery(
		queryKey(projectId),
		(axiosInstance) => {
			return axiosInstance?.get<IGetEnvironmentsLimitation>(
				url(projectId),
			);
		},
		{ enabled },
		scope,
	);
};

export const useCreateProjectEnvironmentMutation = () => {
	const queryClient = useQueryClient();
	const splitEhAndCm = useFeature('SplitEhAndCm');

	const { project_environments, project_environments_v2, project } =
		config.projects;
	const { scope, url: urlV1, queryKey } = project_environments;
	const { url: urlV2 } = project_environments_v2;
	const url = splitEhAndCm ? urlV2 : urlV1;

	return useAuthMutation(
		(axiosInstance) =>
			({
				environment,
				projectId,
			}: {
				environment: IEnvironmentDetails;
				projectId: string;
			}) => {
				return axiosInstance?.post<
					IEnvironmentDetails,
					AxiosResponse<ICreateProjectEnvironmentResponse>
				>(url(projectId), { ...environment });
			},
		{
			onSuccess: ({
				data,
			}: {
				data: ICreateProjectEnvironmentResponse;
			}) => {
				queryClient.invalidateQueries(queryKey(data.projectId));
				queryClient.invalidateQueries(project.queryKey(data.projectId));
			},
		},
		scope,
	);
};

export const useGetProjectEnvironmentsList = ({
	projectId,
	enabled,
}: {
	projectId: string;
	enabled?: boolean;
}) => {
	const splitEhAndCm = useFeature('SplitEhAndCm');
	const isCreateProjectV3 = useFeature('CreateProjectV3');

	const {
		scope,
		url: urlV1,
		queryKey,
	} = config.projects.project_environments;
	const { url: urlV2 } = config.projects.project_environments_v2;
	const shouldUseV2 = isCreateProjectV3 || splitEhAndCm;

	const url = shouldUseV2 ? urlV2 : urlV1;
	return useAuthQuery(
		queryKey(projectId),
		async (axiosInstance) => {
			const result = await axiosInstance?.get<
				IGetEnvironmentResponseV2[]
			>(url(projectId));

			if (isCreateProjectV3) {
				return {
					...result,
					data: result.data.map(normalizeEnvironmentResponse),
				};
			}

			return result;
		},
		{ enabled },
		scope,
	);
};

export const useGetValidateProjectName = (
	projectName: string,
	enabled = false,
) => {
	const { url, scope, queryKey } = config.projects.validate_project_v2;
	return useAuthQuery(
		queryKey(projectName),
		(axiosInstance) => {
			return axiosInstance?.get<IValidateProjectNameResponse>(
				url(projectName),
			);
		},
		{ enabled },
		scope,
	);
};

export const useUnlinkRepositoryMutation = () => {
	const { scope, url } = config.projects.link_repository;
	return useAuthMutation(
		(axiosInstance) => (projectId: string) =>
			axiosInstance?.delete(url(projectId)),
		{},
		scope,
	);
};

export const useLinkRepositoryMutation = ({
	projectId,
}: {
	projectId: string;
}) => {
	const { scope, url } = config.projects.link_repository;
	return useAuthMutation(
		(axiosInstance) => (payload: ILinkRepositoryPayload) => {
			return axiosInstance?.put(url(projectId), payload);
		},
		{},
		scope,
	);
};
