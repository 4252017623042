import {
	useLinkRepositoryEnvironmentMutation,
	useUnLinkRepositoryEnvironmentMutation,
	useUpdateEnvironmentMutation,
} from '../../../services/environments';
import {
	difference,
	ResponseStatusEnum,
} from '../../../../@xmcloud/utils/helpers';
import { useParams } from 'react-router-dom';
import { useCreateEditEnvContext } from './CreateEditEnvContext';
import { isEmpty, pick } from 'lodash';
import { repositoryFields } from '../helper';
import { useToast } from '@chakra-ui/react';
import { editCreateEnvironment } from '../../../../@xmcloud/core/messages/en';
import { t } from '@transifex/native';

const { editSuccess: editSuccesstxt } = editCreateEnvironment;

const { ERROR } = ResponseStatusEnum;

export function useEditEnvironment() {
	const { environmentId } = useParams<{
		environmentId: string;
	}>();
	const toast = useToast();

	const { values, prevValues, isADOProvider, isCreate, onClose } =
		useCreateEditEnvContext();
	const {
		environmentName,
		repositoryBranch,
		repository,
		repositoryId,
		sourceControlIntegrationId,
		sourceControlIntegrationName,
		adoOrganization,
		adoProjectName,
		deployOnCommit,
	} = values;

	const adoRepoPath = `${adoOrganization}/${adoProjectName}/${repository}`;

	const {
		mutate: editEnvironment,
		isLoading: editEnvironmentIsLoading,
		status: editEnvironmentStatus,
	} = useUpdateEnvironmentMutation({
		id: environmentId!,
	});

	const {
		mutate: unLinkRepositoryToEnvironment,
		isLoading: unLinkRepositoryEnvironmentIsLoading,
		status: unLinkRepositoryEnvironmentStatus,
	} = useUnLinkRepositoryEnvironmentMutation();

	const {
		mutate: linkRepositoryToEnvironment,
		isLoading: linkRepositoryEnvironmentIsLoading,
		status: linkRepositoryEnvironmentStatus,
	} = useLinkRepositoryEnvironmentMutation({
		id: environmentId!,
	});

	//1st step: retry edit environment failure
	const shouldRetryFirstStep = editEnvironmentStatus === ERROR;
	// 2nd step: retry unlink repository failure
	const shouldRetrySeconsStep = unLinkRepositoryEnvironmentStatus === ERROR;
	// 3rd step: retry link repository failure
	const shouldRetryThirdProcess = linkRepositoryEnvironmentStatus === ERROR;

	const diffValues = difference(values, prevValues);
	const hasDiffEnvName = 'environmentName' in diffValues;

	const diffRepoDetails = difference(
		pick(values, Object.keys(repositoryFields)),
		pick(prevValues, Object.keys(repositoryFields)),
	);
	const hasDiffRepoDetails = !isEmpty(diffRepoDetails);

	const isDiffEmpty = isEmpty(diffValues);
	const skipFirstStep = !hasDiffEnvName && hasDiffRepoDetails;

	function handleClose() {
		toast({
			status: 'success',
			description: t(editSuccesstxt),
		});
		onClose();
	}

	//3rd step: link repository to environment
	const onLinkRepositoryEnvironment = () => {
		linkRepositoryToEnvironment(
			{
				repository: isADOProvider ? adoRepoPath : repository,
				repositoryId: `${repositoryId}`,
				integrationId: sourceControlIntegrationId,
				repositoryBranch,
				environmentId,
				sourceControlIntegrationName,
				deployOnCommit,
			},
			{
				onSuccess: () => {
					handleClose();
				},
			},
		);
	};

	//2nd step: unlink repository to environment
	const onUnLinkRepositoryEnvironment = () => {
		unLinkRepositoryToEnvironment(
			{
				environmentId: environmentId!,
			},
			{
				onSuccess: () => {
					onLinkRepositoryEnvironment();
				},
			},
		);
	};

	//1st step: creation of environment(onEnvironmentCreation)
	const onEditEnvironment = () => {
		//user may skip the first step if there is no diff in environment name
		if (skipFirstStep) {
			onUnLinkRepositoryEnvironment();
			return;
		}
		const envPayload = {
			name: environmentName,
		};
		editEnvironment(envPayload, {
			onSuccess: () => {
				if (!hasDiffRepoDetails) {
					handleClose();
					return;
				}

				onUnLinkRepositoryEnvironment();
			},
		});
	};

	function onEditEnvironmentProcess() {
		if (shouldRetryFirstStep && !skipFirstStep) {
			onEditEnvironment();
		} else if (shouldRetrySeconsStep) {
			onUnLinkRepositoryEnvironment();
		} else if (shouldRetryThirdProcess) {
			onLinkRepositoryEnvironment();
		} else {
			onEditEnvironment();
		}
	}

	const isLoading =
		editEnvironmentIsLoading ||
		unLinkRepositoryEnvironmentIsLoading ||
		linkRepositoryEnvironmentIsLoading;

	const shouldRetry =
		shouldRetryFirstStep ||
		shouldRetrySeconsStep ||
		shouldRetryThirdProcess;

	const isDisabled = (isDiffEmpty || isLoading) && !isCreate;

	return {
		isLoading,
		onEditEnvironmentProcess,
		shouldRetry,
		isDisabled,
	};
}
