import { object, string } from 'yup';
import { t } from '@transifex/native';
import {
	connections,
	editProject,
	firstStep,
	projects,
	secondStep,
} from '@xmcloud/core/messages/en';
import { ESourceControl } from '@xmcloud/types';

const { requiredName } = projects;
const { maxCharacters } = firstStep;
const { repoRequired, orgRequired, projectNameRequired } = secondStep;
const { requiredConnection } = connections;
const { requiredProvider, forbiddenChars, cannotEndWith } = editProject;

export const createEditProjectSchema = (
	noDefaultRepository: boolean = false,
) => {
	const baseSchema = {
		name: string()
			.required(t(requiredName))
			.max(100, t(maxCharacters))
			// eslint-disable-next-line
			.matches(/^[^<>:"\/\\|?*]*$/, t(forbiddenChars))
			.test(
				'no-dot-end',
				t(cannotEndWith),
				(value) => !value?.endsWith('.'),
			),
		provider: noDefaultRepository
			? string().optional().nullable()
			: string()
					.required(t(requiredProvider))
					.oneOf([ESourceControl.ADO, ESourceControl.GitHub]),
		sourceControlIntegrationId: noDefaultRepository
			? string().optional().nullable()
			: string().required(t(requiredConnection)),
		repository: noDefaultRepository
			? string().optional().nullable()
			: string().required(t(repoRequired)),
		repositoryId: noDefaultRepository
			? string().optional().nullable()
			: string().required(t('Repository ID is required')),
		// Make ADO fields conditional based on provider
		adoOrganization: string().when('provider', {
			is: ESourceControl.ADO,
			then: (schema) => schema.required(t(orgRequired)),
			otherwise: (schema) => schema.optional().nullable(),
		}),
		adoProjectName: string().when('provider', {
			is: ESourceControl.ADO,
			then: (schema) => schema.required(t(projectNameRequired)),
			otherwise: (schema) => schema.optional().nullable(),
		}),
		adoRepository: string().when('provider', {
			is: ESourceControl.ADO,
			then: (schema) => schema.required(t(repoRequired)),
			otherwise: (schema) => schema.optional().nullable(),
		}),
	};

	return object().shape(baseSchema);
};
