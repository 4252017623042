import { FC, useState } from 'react';
import { app, projects } from '../../../@xmcloud/core/messages/en';
import {
	useGetProjectLimitation,
	useGetProjectList,
} from '../../services/projects';
import {
	XMCSButton,
	HeaderStack,
	ToggleStack,
	CreateItemPlaceHolder,
} from '../../shared-components';
import { ProjectsTable } from './components/ProjectsTable';
import { Skeleton, Stack, Tab, TabList, Tooltip } from '@chakra-ui/react';
import { t } from '@transifex/native';
import { handlePush } from '../../../@xmcloud/utils/helpers';
import { Footer } from '../../shared-components';
import { useSharedState } from '../../../@xmcloud/hooks';
import { IS_SM_SCREEN } from '../../../@xmcloud/hooks/context/useSharedState';
import { useCreateProjectV3 } from '../create-project/old-components/useCreateProjectV3';
import { NewFeatureAnnouncement } from '..';

const {
	all,
	my,
	project: enProject,
	canNotCreateProject,
	create: enCreate,
} = projects;

const { projects: enProjects } = app;

export const ProjectsPage: FC = () => {
	const [selectMyProjects, setSelectMyProjects] = useState(false);
	const { data: projectLimitation, isLoading } = useGetProjectLimitation();
	const { enableCreateProjectV3 } = useCreateProjectV3();

	const isCreateButtonDisabled =
		projectLimitation?.data.isLimitExceeds || isLoading;

	const { data: projectsData, isLoading: isProjectLoading } =
		useGetProjectList();
	const allProjects = projectsData?.data || [];
	const length = allProjects.length;
	const { state: isSmScreen } = useSharedState(IS_SM_SCREEN, false);

	const onHandlePush = () => {
		handlePush(
			enableCreateProjectV3
				? '/create-project/1'
				: '/create-project/combined/1',
		);
	};

	if (!length && !isProjectLoading)
		return (
			<CreateItemPlaceHolder
				title={enProject.toLocaleLowerCase()}
				isDisabled={isCreateButtonDisabled}
				onClick={() => onHandlePush()}
				mt={16}
			/>
		);

	return (
		<Stack>
			<HeaderStack title={isProjectLoading ? '' : enProjects}>
				<Tooltip
					label={t(canNotCreateProject)}
					isDisabled={!isCreateButtonDisabled}
					shouldWrapChildren
				>
					{!isSmScreen && (
						<Skeleton isLoaded={!isProjectLoading} rounded="lg">
							<XMCSButton
								text={enCreate}
								isDisabled={isCreateButtonDisabled}
								data-testid="create-project-button"
								onClick={() => onHandlePush()}
							/>
						</Skeleton>
					)}
				</Tooltip>
			</HeaderStack>
			<Skeleton
				isLoaded={!isProjectLoading}
				maxW="xs"
				rounded="lg"
				maxH={10}
			>
				<ToggleStack>
					<TabList>
						<Tab
							onClick={() => setSelectMyProjects(false)}
							data-testid="all-projects-button"
						>
							{all}
						</Tab>
						<Tab
							onClick={() => setSelectMyProjects(true)}
							data-testid="my-projects-button"
						>
							{my}
						</Tab>
					</TabList>
				</ToggleStack>
			</Skeleton>
			<ProjectsTable
				{...{
					selectMyProjects,
					isCreateButtonDisabled,
					allProjects,
					isLoading: isProjectLoading,
				}}
			/>
			{!!isSmScreen && (
				<Footer>
					<XMCSButton
						text={enCreate}
						isDisabled={isCreateButtonDisabled}
						data-testid="create-project-button"
						onClick={() => onHandlePush()}
					/>
				</Footer>
			)}
			<NewFeatureAnnouncement />
		</Stack>
	);
};
