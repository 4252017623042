import {
	Image,
	ModalFooter,
	ButtonGroup,
	Button,
	ModalBody,
	Box,
	Stack,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalHeader,
	Heading,
} from '@chakra-ui/react';
import { spotLifebuoyUrl } from '@xmcloud/utils/constants';
import { useNewAnnouncedFeatures } from './useNewAnnouncedFeatures';

export const NewFeatureAnnouncement: React.FC = () => {
	const {
		isOpen,
		onClose,
		newFeatureAnnouncement,
		title,
		description,
		link,
	} = useNewAnnouncedFeatures();

	if (newFeatureAnnouncement) return null;

	return (
		<Modal onClose={onClose} isOpen={isOpen} size="2xl">
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					<Heading variant="section">NEW FEATURE</Heading>
					{title}
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack
						flexDirection={['column-reverse', 'row']}
						spacing="6"
						alignItems="center"
					>
						<Box>{description}</Box>
						<Image
							boxSize="28"
							src={spotLifebuoyUrl}
							alt="spot-lifebuoy"
						/>
					</Stack>
				</ModalBody>
				<ModalFooter justifyContent="flex-start">
					<ButtonGroup>
						<Button
							data-testid="learn-more-btn"
							variant="solid"
							onClick={() => {
								window.open(link, '_blank');
								onClose();
							}}
						>
							Learn More
						</Button>
						<Button
							variant="outline"
							onClick={onClose}
							data-testid="dismiss-btn"
						>
							Dismiss
						</Button>
					</ButtonGroup>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
