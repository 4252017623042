import {
	IProjectCreateResponse,
	ICreateProjectResponse,
	IGetProjectResponse,
	IGetProjectLimitation,
	IGetProjectResponseV2,
} from '../app/models/projectModel';

export const ProjectCreateMock: IProjectCreateResponse = {
	id: 'mock-project-id-1',
	organizationId: 'mock-org-id',
	organizationName: 'Mock organization',
	name: 'Mock project name',
	region: 'EU',
	createdBy: 'admin',
	createdAt: '2021-08-11T16:17:01.542Z',
	sitecoreMajorVersion: 0,
	editingHostProjectDetails: null,
};

export const ProjectMock: IGetProjectResponse = {
	id: 'mock-project-id-1',
	organizationId: 'mock-org-id',
	organizationName: 'Mock organization',
	name: 'Mock project name',
	region: 'EU',
	createdBy: 'admin',
	createdAt: '2021-08-11T16:17:01.542Z',
	lastUpdatedBy: 'admin',
	lastUpdatedAt: '2021-08-11T16:17:01.542Z',
	environments: ['mock-env-id-1', 'mock-env-id-2', 'mock-env-id-3'],
	repository: 'test-repo',
	sourceControlIntegrationId: 'test-integration-id-1',
	sourceControlIntegrationName: 'test-integration-name-1',
	repositoryId: 'mock-repo-id',
	repositoryLinkedAt: '2024-05-24T16:17:01.542Z',
};

export const ProjectMockV2: IGetProjectResponseV2 = {
	...ProjectMock,
	sitecoreMajorVersion: 0,
	sitecoreMinorVersion: 0,
	environments: [{ id: 'mock-environment-id-1', type: null }],
};

export const ProjectListMock: ICreateProjectResponse[] = [
	{
		id: 'mock-project-id-1',
		organizationId: 'mock-org-id',
		organizationName: 'Mock organization',
		name: 'Mock project name',
		region: 'EU',
		createdBy: 'admin',
		createdAt: '2021-08-11T16:17:01.542Z',
		lastUpdatedBy: 'admin',
		lastUpdatedAt: '2021-08-11T16:17:01.542Z',
		environments: [],
		repositoryId: 'mock-repo-id',
		repository: 'test-repo',
	},
	{
		id: 'mock-project-id-1',
		organizationId: 'mock-org-id',
		organizationName: 'Mock organization',
		name: 'Another project',
		region: 'EU',
		createdBy: 'admin',
		createdAt: '2021-08-11T16:17:01.542Z',
		lastUpdatedBy: 'admin',
		lastUpdatedAt: '2021-06-23T16:17:01.542Z',
		environments: [],
		repositoryId: 'mock-repo-id',
		repository: 'test-repo',
	},
	{
		id: 'mock-project-id-1',
		organizationId: 'mock-org-id',
		organizationName: 'Mock organization',
		name: 'Awesome project',
		region: 'EU',
		createdBy: 'admin',
		createdAt: '2021-08-05T16:17:01.542Z',
		lastUpdatedBy: 'admin',
		lastUpdatedAt: '2021-08-11T16:17:01.542Z',
		environments: [],
		repositoryId: 'mock-repo-id',
		repository: 'test-repo',
	},
	{
		id: 'mock-project-id-1',
		organizationId: 'mock-org-id',
		organizationName: 'Mock organization',
		name: 'New project',
		region: 'EU',
		createdBy: 'admin',
		createdAt: '2021-08-11T16:17:01.542Z',
		lastUpdatedBy: 'admin',
		lastUpdatedAt: '2021-09-21T16:17:01.542Z',
		environments: [],
		repositoryId: 'mock-repo-id',
		repository: 'test-repo',
	},
	{
		id: 'mock-project-id-1',
		organizationId: 'mock-org-id',
		organizationName: 'Mock organization',
		name: 'Project 2',
		region: 'EU',
		createdBy: 'admin',
		createdAt: '2021-10-13T16:17:01.542Z',
		lastUpdatedBy: 'admin',
		lastUpdatedAt: '2021-08-11T16:17:01.542Z',
		environments: [],
		repositoryId: 'mock-repo-id',
		repository: 'test-repo',
	},
	{
		id: 'mock-project-id-1',
		organizationId: 'mock-org-id',
		organizationName: 'Mock organization',
		name: 'Mock project name 2',
		region: 'EU',
		createdBy: 'admin',
		createdAt: '2021-08-11T16:17:01.542Z',
		lastUpdatedBy: 'admin',
		lastUpdatedAt: '2021-08-11T16:17:01.542Z',
		environments: [],
		repositoryId: 'mock-repo-id',
		repository: 'test-repo',
	},
	{
		id: 'mock-project-id-1',
		organizationId: 'mock-org-id',
		organizationName: 'Mock organization',
		name: 'Another project 2',
		region: 'EU',
		createdBy: 'admin',
		createdAt: '2021-08-11T16:17:01.542Z',
		lastUpdatedBy: 'admin',
		lastUpdatedAt: '2021-06-23T16:17:01.542Z',
		environments: [],
		repositoryId: 'mock-repo-id',
		repository: 'test-repo',
	},
	{
		id: 'mock-project-id-8',
		organizationId: 'mock-org-id',
		organizationName: 'Mock organization',
		name: 'Awesome project 2',
		region: 'EU',
		createdBy: 'admin',
		createdAt: '2021-08-05T16:17:01.542Z',
		lastUpdatedBy: 'admin',
		lastUpdatedAt: '2021-08-11T16:17:01.542Z',
		environments: [],
		repositoryId: 'mock-repo-id',
		repository: 'test-repo',
	},
	{
		id: 'mock-project-id-9',
		organizationId: 'mock-org-id',
		organizationName: 'Mock organization',
		name: 'New project 2',
		region: 'EU',
		createdBy: 'admin',
		createdAt: '2021-08-11T16:17:01.542Z',
		lastUpdatedBy: 'admin',
		lastUpdatedAt: '2021-09-21T16:17:01.542Z',
		environments: [],
		repositoryId: 'mock-repo-id',
		repository: 'test-repo',
	},
	{
		id: 'mock-project-id-10',
		organizationId: 'mock-org-id',
		organizationName: 'Mock organization',
		name: 'Project 3',
		region: 'EU',
		createdBy: 'admin',
		createdAt: '2021-10-13T16:17:01.542Z',
		lastUpdatedBy: 'admin',
		lastUpdatedAt: '2021-08-11T16:17:01.542Z',
		environments: [],
		repositoryId: 'mock-repo-id',
		repository: 'test-repo',
	},
	{
		id: 'mock-project-id-11',
		organizationId: 'mock-org-id',
		organizationName: 'Mock organization',
		name: 'Project 11',
		region: 'EU',
		createdBy: 'admin',
		createdAt: '2021-10-13T16:12:01.542Z',
		lastUpdatedBy: 'admin',
		lastUpdatedAt: '2021-08-11T11:17:01.542Z',
		environments: [],
		repositoryId: 'mock-repo-id',
		repository: 'test-repo',
	},
];

export const ProjectListMockV2: IGetProjectResponseV2[] = ProjectListMock.map(
	(project) => ({
		...project,
		sourceControlIntegrationId: 'test-integration-id-1',
		sourceControlIntegrationName: 'test-integration-name-1',
		repositoryLinkedAt: '2024-05-24T16:17:01.542Z',
		sitecoreMajorVersion: 10,
		sitecoreMinorVersion: 1,
		environments: [{ id: 'mock-environment-id-1', type: null }],
	}),
);

export const ProjectLimitationMock: IGetProjectLimitation = {
	count: 1,
	isLimitExceeds: true,
	limit: 1,
};
