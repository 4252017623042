import { fifthStep } from '../../../../@xmcloud/core/messages/en';
import { ESourceControl, TenantType } from '../../../../@xmcloud/types';
import { useCreateProject } from './CreateProjectContext';

const {
	projectName,
	repoName,
	envName,
	prodEnv,
	autoDeploy,
	sourceControlConnection,
	sourceControl,
	adoOrgName: adoOrgNametxt,
	adoProjectName: adoProjectNametxt,
	xmcloudTemplate,
} = fifthStep;

const { GitHub } = ESourceControl;

export const getEnvironmentDetails = ({
	isGithubProvider,
	cardTitle = '',
	sourceControlIntegrationName,
	adoOrganization,
	adoProjectName,
	repository,
	environmentName,
	repositoryBranch,
	tenantType,
	deployOnCommit,
	isByoc,
	isCm,
	key,
}: {
	isGithubProvider: boolean;
	cardTitle?: string;
	sourceControlIntegrationName: string;
	adoOrganization?: string;
	adoProjectName?: string;
	repository: string;
	environmentName: string;
	repositoryBranch: string;
	isByoc: boolean;
	key: number;
	isCm: boolean;
	tenantType?: TenantType;
	deployOnCommit?: boolean;
}) => ({
	key,
	stepNumber: key,
	cardTitle: cardTitle,
	items: [
		{ title: envName, subTitle: environmentName },
		{
			title: sourceControl,
			subTitle: isGithubProvider ? 'GitHub' : 'Azure DevOps',
		},
		{
			title: sourceControlConnection,
			subTitle: sourceControlIntegrationName,
			titleValue: isGithubProvider ? 'GitHub' : 'Azure DevOps',
		},
		...(!!adoOrganization
			? [{ title: adoOrgNametxt, subTitle: adoOrganization }]
			: []),
		...(!!adoProjectName
			? [{ title: adoProjectNametxt, subTitle: adoProjectName }]
			: []),
		{ title: repoName, subTitle: repository },
		...(isByoc ? [{ title: 'Branch', subTitle: repositoryBranch }] : []),
		{
			title: autoDeploy,
			subTitle: deployOnCommit ? 'Yes' : 'No',
		},
		...(isCm
			? [{ title: prodEnv, subTitle: tenantType ? 'Yes' : 'No' }]
			: []),
	],
});

export const useReviewCardList = () => {
	const { values, isByoc, isSingleEnvironmentCreation } = useCreateProject();

	const {
		name,
		cmRepository,
		ehRepository,
		cmEnvironmentName,
		ehEnvironmentName,
		tenantType,
		cmDeployOnCommit,
		ehDeployOnCommit,
		cmSourceControlIntegrationName,
		ehSourceControlIntegrationName,
		cmAdoOrganization,
		cmAdoProjectName,
		ehAdoOrganization,
		ehAdoProjectName,
		cmRepositoryBranch,
		ehRepositoryBranch,
		cmProvider,
		ehProvider,
		template,
	} = values;
	const isEhGithubProvider = ehProvider === GitHub;
	const isCmGithubProvider = cmProvider === GitHub;

	const reviewList = [
		{
			stepNumber: 1,
			cardTitle: 'Set up project details',
			items: [
				{ title: projectName, subTitle: name },
				//IF isByoc is true, then the template is not shown
				...(isByoc
					? []
					: [{ title: xmcloudTemplate, subTitle: template }]),
			],
			key: 1,
		},
		getEnvironmentDetails({
			isGithubProvider: isCmGithubProvider,
			cardTitle: 'Authoring environment details',
			sourceControlIntegrationName: cmSourceControlIntegrationName,
			adoOrganization: cmAdoOrganization,
			adoProjectName: cmAdoProjectName,
			repository: cmRepository,
			environmentName: cmEnvironmentName,
			repositoryBranch: cmRepositoryBranch,
			deployOnCommit: cmDeployOnCommit,
			tenantType,
			isByoc,
			isCm: true,
			key: 2,
		}),
		...(isSingleEnvironmentCreation
			? []
			: [
					getEnvironmentDetails({
						isGithubProvider: isByoc
							? isEhGithubProvider
							: isCmGithubProvider,
						cardTitle: 'Editing host environment details',
						sourceControlIntegrationName: isByoc
							? ehSourceControlIntegrationName
							: cmSourceControlIntegrationName,
						adoOrganization: isByoc
							? ehAdoOrganization
							: cmAdoOrganization,
						adoProjectName: isByoc
							? ehAdoProjectName
							: cmAdoProjectName,
						repository: isByoc ? ehRepository : cmRepository,
						environmentName: ehEnvironmentName,
						repositoryBranch: isByoc
							? ehRepositoryBranch
							: cmRepositoryBranch,
						deployOnCommit: ehDeployOnCommit,
						isByoc,
						isCm: false,
						key: 3,
					}),
				]),
	];

	return { reviewList };
};
