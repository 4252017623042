import * as yup from 'yup';
import {
	firstStep,
	projects,
	environments,
	thirdStep,
	secondStep,
	fourthStep,
	editProject,
} from '../core/messages/en';
import { ENVIRONMENT_NAME_VALIDATION_PATTERN } from '../utils/constants';

const { requiredName } = projects;
const { maxCharacters } = firstStep;
const { requiredEnvironmentName, invalidEnvironmentName, canNotBeSame } =
	environments;
const { maxCharacters: envMaxCharacters } = thirdStep;
const {
	repoRequired,
	orgRequired,
	projectNameRequired,
	repositoryNameValidationMessage,
} = secondStep;
const { requriedCmEnv } = fourthStep;
const { forbiddenChars, cannotEndWith } = editProject;

const notRequired = () => yup.string().notRequired();

const handleName = () =>
	yup
		.string()
		.required(requiredName)
		.max(100, maxCharacters)
		.testForbidChars(forbiddenChars)
		.endsWithForbidChars(cannotEndWith);

const validateRepository = (isRequired: boolean) =>
	isRequired
		? yup
				.string()
				.required(repoRequired)
				.matches(
					/^[A-Za-z0-9_.-]{1,100}$/,
					repositoryNameValidationMessage,
				)
		: notRequired();

const validateAdoProjectName = (isRequired: boolean) =>
	isRequired ? yup.string().required(projectNameRequired) : notRequired();

const validateAdoOrganization = (isRequired: boolean) =>
	isRequired ? yup.string().required(orgRequired) : notRequired();

const validateEnvironment = (nameSpace = '') =>
	yup
		.string()
		.required(requiredEnvironmentName)
		.max(100, envMaxCharacters)
		.matches(
			new RegExp(ENVIRONMENT_NAME_VALIDATION_PATTERN),
			invalidEnvironmentName,
		)
		.when([], {
			is: () => !!nameSpace,
			then: (schema) =>
				schema.notOneOf([yup.ref(nameSpace)], canNotBeSame),
			otherwise: (schema) => schema,
		});

export const createProjectValidationSchema = ({
	providers,
	isByoc,
	enableCreateProjectV3,
}: {
	providers: {
		isAdoProvider: boolean;
		isCmAdoProvider: boolean;
		isEhAdoProvider: boolean;
	};
	isByoc: boolean;
	enableCreateProjectV3: boolean;
}) =>
	enableCreateProjectV3
		? yup.object({
				name: handleName(),
				repository: validateRepository(!isByoc),
				cmRepository: validateRepository(isByoc),
				ehRepository: validateRepository(isByoc),
				cmAdoProjectName: validateAdoProjectName(
					providers.isCmAdoProvider && isByoc,
				),
				ehAdoProjectName: validateAdoProjectName(
					providers.isEhAdoProvider && isByoc,
				),
				cmAdoOrganization: validateAdoOrganization(
					providers.isCmAdoProvider && isByoc,
				),
				ehAdoOrganization: validateAdoOrganization(
					providers.isEhAdoProvider && isByoc,
				),
				cmEnvironmentName: validateEnvironment('ehEnvironmentName'),
				ehEnvironmentName: validateEnvironment('cmEnvironmentName'),
				cmEnvironmentId: yup.string().required(requriedCmEnv),
			})
		: yup.object({
				name: handleName(),
				repository: validateRepository(true),
				adoProjectName: validateAdoProjectName(providers.isAdoProvider),
				adoOrganization: validateAdoOrganization(
					providers.isAdoProvider,
				),
				environmentName: validateEnvironment(),
			});
